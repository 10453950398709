import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import NProgress from 'nprogress';
import FormStrategy from "@/utils/FormStrategy"; // 确保路径正确
// 导入子模块路由
// import moduleARoutes from '@/modules/router';
// import massProcessRouter from '@/modules/router/massProcessRouter';
import allRoutes from "@/router/allRoutes";
const wxlogin = (resolve) => require(["@/views/login/index.vue"], resolve);
const wxbase = (resolve) => require(["@/views/WX/base.vue"], resolve);
const wxMentList = (resolve) => require(["@/views/WX/menuList.vue"], resolve);
const flowPath = (resolve) => require(["@/views/WX/flowPath.vue"], resolve);
const basepointsetting = (resolve) => require(["@/views/WX/basepointsetting.vue"], resolve);
const attendanceBase = (resolve) => require(["@/views/WX/attendanceBase.vue"], resolve);
const wxmessage = (resolve) => require(["@/views/WX/message.vue"], resolve);
const wxemail = (resolve) => require(["@/views/WX/email.vue"], resolve);
const wxdoc = (resolve) => require(["@/views/WX/doc.vue"], resolve);
const wxwork = (resolve) => require(["@/views/WX/work.vue"], resolve);
const wxphone = (resolve) => require(["@/views/WX/phone.vue"], resolve);
const wxchat = (resolve) => require(["@/views/WX/chat.vue"], resolve);
const wxinfo = (resolve) => require(["@/views/WX/info.vue"], resolve);
const wxlocation = (resolve) => require(["@/views/locationReporting/locationTabBar.vue"], resolve); //位置上报
const reporting = (resolve) => require(["@/views/locationReporting/reporting.vue"], resolve); //位置上报
const record = (resolve) => require(["@/views/locationReporting/record.vue"], resolve); //位置上报--记录
const wxclockin = (resolve) => require(["@/views/attendance/clockTabBar.vue"], resolve);
const statistics = (resolve) => require(["@/views/attendance/statistics.vue"], resolve);
const apply = (resolve) => require(["@/views/attendance/apply.vue"], resolve);
const clockin = (resolve) => require(["@/views/attendance/clockin.vue"], resolve);
const goOut = (resolve) => require(["@/views/go-out/index.vue"], resolve);
const goOutView = (resolve) => require(["@/views/go-out/indexView.vue"], resolve);
const chairmansPortal = (resolve) => require(["@/views/chairmans-portal/index.vue"], resolve);
const leave = (resolve) => require(["@/views/leave/index.vue"], resolve);
const attendanceAppeal = (resolve) => require(["@/views/attendance-appeal/index.vue"], resolve); // 申诉
const siteLogs = (resolve) => require(["@/views/siteLogs/index.vue"], resolve); // 日志
const siteLogsAll = (resolve) => require(["@/views/siteLogs-all/index.vue"], resolve); // 全员日志
const siteLogsOrg = (resolve) => require(["@/views/siteLogs-org/index.vue"], resolve); // 部门日志
const siteLogsOne = (resolve) => require(["@/views/siteLogs-one/index.vue"], resolve); // 个人日志
const personalLogs = (resolve) => require(["@/views/siteLogs/personalLogs/index.vue"], resolve); // 个人日志
const myLogs = (resolve) => require(["@/views/siteLogs/myLogs/index.vue"], resolve); // 我的日志
const attendanceAppealView = (resolve) => require(["@/views/attendance-appeal/indexView.vue"], resolve);
const businessTrips = (resolve) => require(["@/views/business-trips/index.vue"], resolve);
const businessTripsView = (resolve) => require(["@/views/business-trips/indexView.vue"], resolve);
const email = (resolve) => require(["@/views/email/index.vue"], resolve);
const inbox = (resolve) => require(["@/views/email/inbox.vue"], resolve);
const outbox = (resolve) => require(["@/views/email/outbox.vue"], resolve);
const boxProcessed = (resolve) => require(["@/views/email/boxProcessed.vue"], resolve);
const writeEmail = (resolve) => require(["@/views/email/writeEmail.vue"], resolve);
const viewEmail = (resolve) => require(["@/views/email/viewEmail.vue"], resolve);
const flowChart = (resolve) => require(["@/components/flowChart/flowChart.vue"], resolve);
const flowChartDetail = (resolve) => require(["@/components/flowChart/flowChartDetail.vue"], resolve);
const businessPortal = (resolve) => require(["@/views/businessPortal/index.vue"], resolve);
const engineeringPortal = (resolve) => require(["@/views/engineering-portal/index.vue"], resolve);
const projectledger = (resolve) => require(["@/views/engineering-portal/projectledger.vue"], resolve);
const ProjectMonth = (resolve) => require(["@/views/engineering-portal/ProjectMonth.vue"], resolve);
const ProjectList = (resolve) => require(["@/views/engineering-portal/ProjectList.vue"], resolve);
const projectDetail = (resolve) => require(["@/views/engineering-portal/projectDetail.vue"], resolve);
const Patrol = (resolve) => require(["@/views/engineering-portal/children/Patrol.vue"], resolve);
const PatrolList = (resolve) => require(["@/views/engineering-portal/children/PatrolList.vue"], resolve);
const Bystander = (resolve) => require(["@/views/engineering-portal/children/Bystander.vue"], resolve);
const Acceptance = (resolve) => require(["@/views/engineering-portal/children/Acceptance.vue"], resolve);
const EquipmentInformation = (resolve) => require(["@/views/engineering-portal/children/EquipmentInformation.vue", ], resolve);
const projectInfo = (resolve) => require(["@/views/engineering-portal/projectInfo.vue"], resolve);
const Materialentry = (resolve) => require(["@/views/engineering-portal/children/Materialentry.vue"], resolve);
const SupervisionNotice = (resolve) => require(["@/views/engineering-portal/children/SupervisionNotice.vue", ], resolve);
const WorkContactForm = (resolve) => require(["@/views/engineering-portal/children/WorkContactForm.vue"], resolve);
const DailyWorklogs = (resolve) => require(["@/views/engineering-portal/children/DailyWorklogs.vue"], resolve);
const SupervisionLog = (resolve) => require(["@/views/engineering-portal/children/SupervisionLog.vue"], resolve);
const sealApproval = (resolve) => require(["@/views/processManagement/children/sealApproval.vue"], resolve); // 印章审批
// const sealApprovalView = (resolve) => require(["@/views/processManagement/children/sealApprovalView.vue"], resolve); // 印章审批详情
const mySend = (resolve) => require(["@/views/processManagement/children/mySend.vue"], resolve); // 我的发送(盖章，外出)
const review = (resolve) => require(["@/views/processManagement/children/review.vue"], resolve); // 收文登记审核
const processApplication = (resolve) => require(["@/views/processManagement/children/processApplication.vue", ], resolve);
const processManagement = (resolve) => require(["@/views/processManagement/index.vue"], resolve);
const processed = (resolve) => require(["@/views/processManagement/children/processed.vue"], resolve);
const theProcessIInitiated = (resolve) => require(["@/views/processManagement/children/theProcessIInitiated.vue", ], resolve);
const toDoProcess = (resolve) => require(["@/views/processManagement/children/toDoProcess.vue"], resolve);
const goHandle = (resolve) => require(["@/views/attendance/staticComponents/goHandle.vue"], resolve);
const toLeave = (resolve) => require(["@/views/attendance/staticComponents/leave.vue"], resolve);
const calendar = (resolve) => require(["@/views/attendance/staticComponents/calendar.vue"], resolve);
const toTrips = (resolve) => require(["@/views/attendance/staticComponents/trips.vue"], resolve);
const AcceptanceList = (resolve) => require(["@/views/engineering-portal/children/AcceptanceList.vue"], resolve);
const EquipmentInformationList = (resolve) => require(["@/views/engineering-portal/children/EquipmentInformationList.vue", ], resolve);
const BystanderList = (resolve) => require(["@/views/engineering-portal/children/BystanderList.vue"], resolve);
const MaterialentryList = (resolve) => require(["@/views/engineering-portal/children/MaterialentryList.vue", ], resolve);
const feedbackOnGoingOut = (resolve) => require(["@/views/feedbackOnGoingOut/index.vue"], resolve); //外出反馈
const feedbackOnGoingOutView = (resolve) => require(["@/views/feedbackOnGoingOut/view.vue"], resolve); //外出反馈详情
const feedbackOnGoingOutList = (resolve) => require(["@/views/feedbackOnGoingOut/list.vue"], resolve); //外出反馈列表
const feedbackOnGoingOutEdit = (resolve) => require(["@/views/feedbackOnGoingOut/edit.vue"], resolve); //外出反馈编辑
const personalLogRecords = (resolve) => require(["@/views/siteLogs/logging/index.vue"], resolve); //个人日志记录
const personalLogRecordsAll = (resolve) => require(["@/views/siteLogs-all/logging/index.vue"], resolve); //全员日志记录
const personalLogRecordsOrg = (resolve) => require(["@/views/siteLogs-org/logging/index.vue"], resolve); //部门日志记录
const personalLogRecordsOne = (resolve) => require(["@/views/siteLogs-one/logging/index.vue"], resolve); //个人日志记录
const recordDetails = (resolve) => require(["@/views/siteLogs/logging/children/recordDetails.vue"], resolve); //个人日志记录
Vue.use(Router);
const routesMap = [{
        path: "/",
        name: "wxMentList",
        component: wxMentList,
        beforeEnter: (to, from, next) => {
            console.log('Entering menu route');
            next();
        },
        meta: {
            title: "天衡信息化",
            keepAlive: false,
            isBack: false,
        },
    },
    {
        path: "/wxlogin",
        name: "wxlogin",
        component: wxlogin,
    },

    {
        path: "/wxchat",
        name: "wxchat",
        component: wxchat,
    },

    {
        path: "/attendance",
        name: "wxclockin",
        redirect: "/attendance/clockin",
        component: wxclockin,
        children: [{
                //打卡
                path: "/attendance/clockin",
                name: "clockin",
                component: clockin,
                meta: { title: "打卡", keepAlive: false, needLogin: false },
            },
            {
                //申请
                path: "/attendance/apply",
                name: "apply",
                component: apply,
                meta: { title: "申请", keepAlive: false, needLogin: false },
            },
            {
                //统计
                path: "/attendance/statistics",
                name: "statistics",
                component: statistics,
                meta: { title: "统计", keepAlive: false },
            },
            {
                //统计-处理考勤
                path: "/attendance/goHandle",
                name: "goHandle",
                component: goHandle,
                meta: { title: "统计", keepAlive: false },
            },
            {
                //统计-处理考勤-请假
                path: "/attendance/toLeave",
                name: "toLeave",
                component: toLeave,
                meta: { title: "统计", keepAlive: false },
            },
            {
                //统计-处理考勤-出差
                path: "/attendance/trips",
                name: "toTrips",
                component: toTrips,
                meta: { title: "统计", keepAlive: false },
            },
            {
                //统计-打卡月历
                path: "/attendance/calendar",
                name: "calendar",
                component: calendar,
                meta: { title: "统计", keepAlive: false },
            },
        ],
    },

    {
        path: "/locationReporting",
        name: "wxlocation",
        redirect: "/locationReporting/reporting",
        component: wxlocation,
        children: [{
                //位置上报
                path: "/locationReporting/reporting",
                name: "reporting",
                component: reporting,
                meta: { title: "位置上报", keepAlive: false, needLogin: false },
            },
            {
                //记录
                path: "/locationReporting/record",
                name: "record",
                component: record,
                meta: { title: "记录", keepAlive: false, needLogin: false },
            },
        ],
    },

    {
        path: "/wxinfo",
        name: "wxinfo",
        component: wxinfo,
    },

    {
        path: "/flowPath",
        name: "flowPath",
        component: flowPath,
    },

    {
        path: "/basepointsetting",
        name: "basepointsetting",
        component: basepointsetting,
        meta: { title: "基点设置" },
    },

    {
        path: "/attendanceBase",
        name: "attendanceBase",
        component: attendanceBase,
        meta: { title: "考勤基点设置" },
    },
    {
        path: "/chairmansPortal",
        name: "chairmansPortal",
        component: chairmansPortal,
        meta: { title: "董事长门户" },
    },
    {
        path: "/businessPortal",
        name: "businessPortal",
        component: businessPortal,
        meta: { title: "经营门户" },
    },
    {
        path: "/projectledger",
        name: "projectledger",
        component: projectledger,
        meta: { title: "项目台账" },
    },
    {
        path: "/ProjectMonth",
        name: "ProjectMonth",
        component: ProjectMonth,
        meta: { title: "项目月报" },
    },

    {
        path: "/engineeringPortal",
        name: "engineeringPortal",
        component: engineeringPortal,
        meta: { title: "工程门户" },
    },
    {
        path: "/projectDetail",
        name: "projectDetail",
        component: projectDetail,
        meta: { title: "工程门户" },
    },
    {
        path: "/Patrol",
        name: "Patrol",
        component: Patrol,
        meta: { title: "巡视" },
    },
    {
        path: "/PatrolList",
        name: "PatrolList",
        component: PatrolList,
        meta: { title: "巡视列表" },
    },
    {
        path: "/BystanderList",
        name: "BystanderList",
        component: BystanderList,
        meta: { title: "旁站列表" },
    },

    {
        path: "/AcceptanceList",
        name: "AcceptanceList",
        component: AcceptanceList,
        meta: { title: "验收列表" },
    },

    {
        path: "/EquipmentInformationList",
        name: "EquipmentInformationList",
        component: EquipmentInformationList,
        meta: { title: "材料设备信息列表" },
    },

    {
        path: "/MaterialentryList",
        name: "MaterialentryList",
        component: MaterialentryList,
        meta: { title: "材料进场列表" },
    },

    {
        path: "/leave",
        name: "leave",
        component: leave,
        meta: { title: "请假" },
    },
    {
        path: "/email",
        name: "email",
        component: email,
        redirect: "/email/inbox",
        meta: { title: "邮件" },
        children: [{
                path: "/email/inbox",
                name: "inbox",
                component: inbox,
                meta: { title: "收件箱" },
            },
            {
                path: "/email/outbox",
                name: "outbox",
                component: outbox,
                meta: { title: "发件箱" },
            },
            {
                path: "/email/boxProcessed",
                name: "boxProcessed",
                component: boxProcessed,
                meta: { title: "已办箱" },
            },
            {
                path: "/email/writeEmail",
                name: "writeEmail",
                component: writeEmail,
                meta: { title: "写邮件" },
            },
        ],
    },
    {
        path: "/email/viewEmail",
        name: "viewEmail",
        component: viewEmail,
        meta: { title: "查看邮件" },
    },

    {
        path: "/ProjectList",
        name: "ProjectList",
        component: ProjectList,
        meta: { title: "工程门户" },
    },

    {
        path: "/Bystander",
        name: "Bystander",
        component: Bystander,
        meta: { title: "旁站" },
    },
    {
        path: "/Acceptance",
        name: "Acceptance",
        component: Acceptance,
        meta: { title: "验收" },
    },
    {
        path: "/EquipmentInformation",
        name: "EquipmentInformation",
        component: EquipmentInformation,
        meta: { title: "材料设备信息" },
    },

    {
        path: "/projectInfo",
        name: "projectInfo",
        component: projectInfo,
        meta: { title: "项目基本信息" },
    },
    {
        path: "/Materialentry",
        name: "Materialentry",
        component: Materialentry,
        meta: { title: "材料进场" },
    },
    {
        path: "/SupervisionNotice",
        name: "SupervisionNotice",
        component: SupervisionNotice,
        meta: { title: "监理通知单" },
    },
    {
        path: "/WorkContactForm",
        name: "WorkContactForm",
        component: WorkContactForm,
        meta: { title: "工作联系单" },
    },
    // {
    //   path: "/SupervisionLog",
    //   name: "SupervisionLog",
    //   component: SupervisionLog,
    //   meta: { title: "监理日志" },
    // },
    {
        path: "/DailyWorklogs",
        name: "DailyWorklogs",
        component: DailyWorklogs,
        meta: { title: "总监代日常工作日志" },
    },
    {
        path: "/goOut",
        name: "goOut",
        component: goOut,
        meta: { title: "外出" },
    },
    {
        path: "/feedbackOnGoingOut",
        name: "feedbackOnGoingOut",
        component: feedbackOnGoingOut,
        meta: { title: "外出反馈" },
    },
    {
        path: "/feedbackOnGoingOutView",
        name: "feedbackOnGoingOutView",
        component: feedbackOnGoingOutView,
        meta: { title: "外出反馈" },
    },
    {
        path: "/feedbackOnGoingOutList",
        name: "feedbackOnGoingOutList",
        component: feedbackOnGoingOutList,
        meta: { title: "外出反馈列表" },
    },
    {
        path: "/feedbackOnGoingOutEdit",
        name: "feedbackOnGoingOutEdit",
        component: feedbackOnGoingOutEdit,
        meta: { title: "外出反馈编辑" },
    },

    {
        path: "/goOutView",
        name: "goOutView",
        component: goOutView,
        meta: { title: "外出" },
    },
    {
        path: "/recordDetails/:id",
        name: "recordDetails",
        component: recordDetails,
        meta: { title: "个人日志详情" },
    },
    {
        path: "/siteLogs",
        name: "siteLogs",
        redirect: "/siteLogs/personalLogs",
        component: siteLogs,
        meta: { title: "工作日志" },
        children: [{
                path: "/siteLogs/personalLogs",
                name: "personalLogs",
                component: personalLogs,
                meta: { title: "个人日志" },
            },
            {
                path: "/siteLogs/personalLogRecords",
                name: "personalLogRecords",
                component: personalLogRecords,
                meta: { title: "日志记录" },
            },
        ]
    },
    {
        path: "/siteLogs-all",
        name: "siteLogsAll",
        redirect: "/siteLogs-all/personalLogs",
        component: siteLogsAll,
        meta: { title: "工作日志(全员)" },
        children: [{
                path: "/siteLogs-all/personalLogs",
                name: "personalLogsAll",
                component: personalLogs,
                meta: { title: "个人日志" },
            },
            {
                path: "/siteLogs-all/personalLogRecords",
                name: "personalLogRecordsAll",
                component: personalLogRecordsAll,
                meta: { title: "部门日志" },
            },
            {
                path: "/siteLogs-all/myLogs",
                name: "myLogsAll",
                component: myLogs,
                meta: { title: "我的日志" },
            },
        ]
    },
    {
        path: "/siteLogs-org",
        name: "siteLogsOrg",
        redirect: "/siteLogs-org/personalLogs",
        component: siteLogsOrg,
        meta: { title: "工作日志(部门)" },
        children: [{
                path: "/siteLogs-org/personalLogs",
                name: "personalLogsOrg",
                component: personalLogs,
                meta: { title: "个人日志" },
            },
            {
                path: "/siteLogs-org/personalLogRecords",
                name: "personalLogRecordsOrg",
                component: personalLogRecordsOrg,
                meta: { title: "部门日志" },
            },
            {
                path: "/siteLogs-org/myLogs",
                name: "myLogsOrg",
                component: myLogs,
                meta: { title: "我的日志" },
            },
        ]
    },
    {
        path: "/siteLogs-one",
        name: "siteLogsOne",
        redirect: "/siteLogs-one/personalLogs",
        component: siteLogsOne,
        meta: { title: "工作日志(个人)" },
        children: [{
                path: "/siteLogs-one/personalLogs",
                name: "personalLogsOne",
                component: personalLogs,
                meta: { title: "个人日志" },
            },
            {
                path: "/siteLogs-one/myLogs",
                name: "myLogsOne",
                component: myLogs,
                meta: { title: "我的日志" },
            },
        ]
    },
    {
        path: "/email",
        name: "email",
        component: email,
        redirect: "/email/inbox",
        meta: { title: "邮件" },
        children: [{
                path: "/email/inbox",
                name: "inbox",
                component: inbox,
                meta: { title: "收件箱" },
            },
            {
                path: "/email/outbox",
                name: "outbox",
                component: outbox,
                meta: { title: "发件箱" },
            },
            {
                path: "/email/boxProcessed",
                name: "boxProcessed",
                component: boxProcessed,
                meta: { title: "已办箱" },
            },
            {
                path: "/email/writeEmail",
                name: "writeEmail",
                component: writeEmail,
                meta: { title: "写邮件" },
            },
        ],
    },

    {
        path: "/businessTrips",
        name: "businessTrips",
        component: businessTrips,
        meta: { title: "出差" },
    },
    {
        path: "/businessTripsView",
        name: "businessTripsView",
        component: businessTripsView,
        meta: { title: "出差" },
    },
    {
        path: "/attendanceAppeal",
        name: "attendanceAppeal",
        component: attendanceAppeal,
        meta: {
            title: "申诉",
            keepAlive: true,
            isBack: false,
            cacheTo: allRoutes,
        },
    },
    {
        path: "/attendanceAppealView",
        name: "attendanceAppealView",
        component: attendanceAppealView,
        meta: { title: "申诉" },
    },
    {
        path: "/flowChart",
        name: "flowChart",
        component: flowChart,
        meta: { title: "流程图" },
    },
    {
        path: "/flowChartDetail",
        name: "flowChartDetail",
        component: flowChartDetail,
        meta: { title: "流程图详情" },
    },
    {
        path: "/sealApproval",
        name: "sealApproval",
        component: sealApproval,
        queryTransform: (item) => ({
            id: item.businessCode,
        }),
        meta: { title: "盖章审批" },
    },
    // {
    //   path: "/sealApprovalView",
    //   name: "sealApprovalView",
    //   component: sealApprovalView,
    //   queryTransform: (item) => ({
    //     id: item.id,
    //   }),
    //   meta: { title: "盖章审批" },
    // },

    {
        path: "/mySend",
        name: "mySend",
        component: mySend,
        meta: { title: "我发起的" },
    },
    {
        path: "/processApplication",
        name: "processApplication",
        component: processApplication,
        meta: { title: "我发起的" },
    },
    {
        path: "/processManagement",
        name: "processManagement",
        component: processManagement,
        meta: { title: "流程管理" },
        redirect: "/toDoProcess",
        children: [{
                path: "/toDoProcess",
                name: "toDoProcess",
                component: toDoProcess,
                meta: {
                    title: "待办流程",
                    keepAlive: false,
                    isBack: false,
                    cacheTo: allRoutes,
                },
            },
            {
                path: "/processed",
                name: "processed",
                component: processed,
                meta: {
                    title: "已办流程",
                    keepAlive: true,
                    isBack: false,
                    cacheTo: allRoutes,
                },
            },
            {
                path: "/theProcessIInitiated",
                name: "theProcessIInitiated",
                component: theProcessIInitiated,
                meta: {
                    title: "我发起的流程",
                    keepAlive: true,
                    isBack: false,
                    cacheTo: allRoutes,
                },
            },
        ],
    },
    // 将子模块路由合并到主路由中
    ...allRoutes,
];
const routeMap = new Router({
    routes: routesMap,
});
// // const strategy = new FormStrategy(routeMap  keyi);
// export { routesMap };

//取之前存储的主题颜色值
function resetScrollAndSetThemeColor() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    const themeColor = sessionStorage.getItem("themeColor");
    if (themeColor) {
        document.documentElement.style.setProperty("--theme-color", themeColor);
    }
}

// 去除链接中的code和state
function getUrlCode() {
    var url = location.search;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
    }
    return theRequest;
}

//是用来处理微信OAuth认证流程的
function handleWechatOAuth() {
    const userId = localStorage.getItem("user_id");
    const currentUrl = window.location.href;
    const code = getUrlCode().code;
    console.log(code, "保存或发送 code 到后");
    const appId = "ww23783e07fe0f7259";
    const agentId = "1000036";
    const timestamp = String(new Date() * 1);
    if (code == null || code === "") {
        const oauthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${encodeURIComponent(
      appId
    )}&redirect_uri=${encodeURIComponent(
      "https://wx.gdtianheng.com"
    )}&state=${timestamp}&agentid=${agentId}&response_type=code&scope=snsapi_base#wechat_redirect`;
        window.location.href = oauthUrl;
    } else {
        localStorage.removeItem("state");
    }
}

// routeMap.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   if (from.meta.cacheTo) {
//     from.meta.cacheTo.forEach((item) => {
//       if (Array.isArray(item) || typeof item === 'string') {
//         if (item.includes(to.name)) {
//           from.meta.keepAlive = true;
//         } else {
//           from.meta.keepAlive = false;
//         }
//       } else {
//         console.warn(`Expected string or array, got ${typeof item} instead.`);
//       }
//     });
//   }
//   next();
//   try {
//     resetScrollAndSetThemeColor();
//     // handleWechatOAuth();
//   } catch (error) {
//     console.error('An error occurred in beforeEach:', error);
//     next(error); // 或者 next(false) 来中断导航
//   }
// });

let lastRoute = null;

function handlePopState(to, from) {
    const currentRoute = routeMap.currentRoute.name;
    if (lastRoute && currentRoute !== lastRoute) {
        console.log(`Navigating from ${currentRoute} to ${lastRoute}`);
    } else {
        console.log(`Navigating to ${currentRoute}`);
    }
}
window.addEventListener("popstate", () => {
    // 当 popstate 事件触发时，记录当前路由
    lastRoute = routeMap.currentRoute.name;
    handlePopState();
});
routeMap.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (from.meta.cacheTo) {
        from.meta.cacheTo.forEach((item) => {
            if (Array.isArray(item) || typeof item === "string") {
                if (item.includes(to.name)) {
                    from.meta.keepAlive = true;
                } else {
                    from.meta.keepAlive = false;
                }
            } else {
                console.warn(`Expected string or array, got ${typeof item} instead.`);
            }
        });
    }
    // 判断是否通过右滑返回
    const isSwipeBack = lastRoute && lastRoute.fullPath === from.fullPath;
    if (isSwipeBack) {
        console.log("Detected swipe back gesture.");
    }
    lastRoute = null; // 清空 lastRoute，避免重复检测
    NProgress.start(); // 开始进度条
    next();
    try {
        resetScrollAndSetThemeColor();
        handleWechatOAuth(); // 处理微信 OAuth
    } catch (error) {
        console.error("An error occurred in beforeEach:", error);
        next(error); // 或者 next(false) 来中断导航
    }
});

routeMap.afterEach((to) => {
    NProgress.done(); // 结束进度条
});

//防止路由重复点击报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export default routeMap;