// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 的样式
Vue.use(ElementUI);
import Vant from "vant";
import { Toast } from "vant";
import "@/assets/iconfont.ttf"; // 引入字体文件
import "@/assets/iconfont.css";
import axios from "axios";
import store from './store';
import echarts from "@/utils/echarts.js";
import "@/style/common.less";
import "@/style/process.css";
// import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;
import VueFormulate from '@braid/vue-formulate';
Vue.use(VueFormulate);

import Vuex from "vuex"
Vue.use(Vuex)
Vue.prototype.$http = axios;
console.log(axios);
import Axios from "axios";
Axios.defaults.baseURL = "/api";
Vue.prototype.$http = Axios;
Vue.prototype.$toast = Toast;
Axios.defaults.headers.post["Content-Type"] = "application/json";
// require("./utils/adapter")(document, window);
import VConsole from "vconsole";
import moment from "moment";
moment.locale("zh-cn");
Vue.prototype.$moment = moment;
import wx from "weixin-js-sdk";
Vue.prototype.$wx = wx;
import "vant/lib/index.css";
import { Lazyload } from "vant";
Vue.use(Vant);
import { RadioGroup, Radio } from "vant";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.config.productionTip = false;
Vue.use(Lazyload, {
    lazyComponent: true,
});
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
    ak: "aXY6TKkGf5dvrCAkYaE3LnZuB1ykN9Pz",
});
import VueHtml5Plus from 'vue-html5plus';
// Vue.use(VueHtml5Plus)
Vue.prototype.$VueHtml5Plus = VueHtml5Plus;
import plus from '@h5plus/core'
// Vue.use(plus)
Vue.prototype.$plus = plus;
Vue.prototype.resetSetItem = function(key, newVal) {
    //监听的session字段
    if (key === 'isStaticTabBar') {
        // 创建一个StorageEvent事件
        const storage = {
            setItem: function(k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                const newStorageEvent = new CustomEvent('setItem', {
                    bubbles: false,
                    cancelable: false,
                    detail: {
                        key: k,
                        oldValue: null,
                        newValue: val,
                        url: null
                    }
                });
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import { FileIcon } from 'vant-green';
Vue.use(FileIcon);

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({
    showSpinner: false, // 可选，关闭默认的 spinner
});
import TaskTheme from '@/components/TaskTheme.vue';
Vue.component('TaskTheme', TaskTheme);

// 定义全局变量
Vue.prototype.$workFlow_TaskTheme = '';

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "";
    if (to.query.workFlow_TaskTheme) {
        Vue.prototype.$workFlow_TaskTheme = to.query.workFlow_TaskTheme;
        console.log('workFlow_TaskTheme 参数存在:', to.query.workFlow_TaskTheme);
    } else {
        console.log('workFlow_TaskTheme 参数不存在');
    }
    next();
});
/* eslint-disable no-new */
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");